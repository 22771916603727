import axios from 'axios'
import {
  camelToSnakeCase,
  everyPropKey,
  snakeToCamelCase,
  staySelectedProps
} from "@/utils/dataTransformation";
import {prepareList} from "@/services/apiService";

export const fetchUsers = async (pagination) => {
  const preparedPagination = everyPropKey(pagination, camelToSnakeCase)
  if (preparedPagination.sort_by) {
    preparedPagination.sort_by = camelToSnakeCase(preparedPagination.sort_by)
  }
  const {data} = await axios.post('users', preparedPagination)
  const preparedData = prepareList(data.data, snakeToCamelCase, staySelectedProps)([
    'id',
    'firstName',
    'lastName',
    'email',
    ['role', []],
    ['redtrainId', null]
  ], '')
  return {
    data: preparedData,
    totalElements: data.total
  }
}
export const fetchUser = async (id) => {
  const {data} = await axios.get(`user/${id}`)
  const camelCasedObj = everyPropKey(data, snakeToCamelCase)
  
  return staySelectedProps(camelCasedObj, [
    'firstName',
    'lastName',
    'email',
    ['roleId', null],
    ['redtrainId', null]
  ], '')
}

export const fetchUserRoles = async () => {
  const {data} = await axios.get('roles')
  return data.map(({id, name}) => ({id, name}))
}

export const removeUser = async (id) => {
  await axios.delete(`user/${id}`)
}

export const saveUser = async (user, id = null) => {
  const method = id ? 'put' : 'post'
  const path = id ? `user/${id}` : 'user'
  await axios[method](path, everyPropKey(user, camelToSnakeCase))
}
